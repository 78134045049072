export const ROOT = "/";
export const OVERVIEW = "/overview";
export const ANALYTICS = "/analytics";
export const WORK_LIST = "/workList";
export const WORK_LIST_NEW = "/workList/new";
export const REPORTS = "/reports";
export const LOGIN = "/login";
export const INSIGHTS ="/insights";

export const PORTFOLIO_SUMMARY = "/portfolio";
export const RESET = "/reset-password";
export const FORGOT_PASSWORD = "/forgot-password";
export const PERMISSION = "/permission";
export const PERMISSION_SETTING = "/permission/settings";
export const DASHBOARD = "/dashboard";
export const PAGE1 = "/page1";
export const AUTH_PAGE1 = "/authorized1";
export const PROFILE = "/profile";
// export const ACCOUNT = "/account";
export const LANDING_PAGE_LOGIN = "/LandingPageLogin";
export const RIPAIR_SERVICE_ESTIMATE = "/RepairServiceEstimate";
// export const WITHOUTSPARE_REPAIR_OPTION = "/WithoutSpareParts";
export const WITH_SPARE_PARTS = "/RepairWithSpareParts/BuilderDetails";
export const PART_LIST = "/RepairPartList/PartList";
export const REPAIR_PARTLIST = "/RepairPartlist";
export const REPAIR_WITH_SPARE_PARTS = "/RepairWithSpareParts";
export const REPAIR_WITHOUT_SPARE_PARTS = "/RepairWithoutSpareParts";
// export const REPAIR_SERVICE_PARTS_TEMPLATE = "/RepairServicePartTemplate";
export const TEMPLATE = "/standard-job";
export const REPAIR_KITS = "/RepairKits";
export const STANDARD_JOB_DETAIL = "/standard-job/details";
// export const SERVICE_PART_TEMPLATES = "/RepairServicePartTemplate/ServicePartTemplates";
export const KITS = "/RepairKits/Kits";
export const WITHOUT_SPARE_PARTS_DETAILS = "/RepairWithoutSpareParts/BuilderDetails";
export const QUOTE_SPARE_PARTS ="/spare-part-quote";
export const QUOTE_SPARE_PARTS_SEARCH ="/spare-part-quote/search";
export const QUOTE_SPARE_PART_CONFIGURATION ="/spare-part-quote/create";
export const QUOTE_RECENT_REPAIR ="/repair-quote";
export const QUOTE_REPAIR_SEARCH ="/repair-quote/search";
export const QUOTE_REPAIR_CREATE ="/repair-quote/create";
export const SOLUTION_QUOTE ="/SolutionQuote";
export const SOLUTION_QUOTE_SEARCH ="/SolutionQuoteSearch";
export const SOLUTION_QUOTE_CONFIG ="/SolutionQuoteConfiguration";
export const SOLUTION_SERVICE_PORTFOLIO ="/SolutionServicePortfolio";
export const SPARE_PARTS_QUOTE_DETAILS ="/spare-part-quote/details";
export const QUOTE_SOLUTION_BUILDER ="/QuoteSolutionBuilder";
export const REPAIR_QUOTE_WITH_EVALUATION ="/repair-quote/evaluation";
export const REPAIR_QUOTE_DETAILS ="/repair-quote/details";
export const SOLUTION_SEARCH_TEMPLATE ="/SolutionSearchTemplate";
export const QUOTE_SOLUTION_CONFIGURATION ="/QuoteSolutionConfiguration";
export const SHOPPING_CART_LIST ="/ShoppingCartList";
export const PRICE_SETTING ="/PriceSetting";
export const PRICE_CONFIGURATION ="/PriceConfiguration";
export const PRICE_GLOBAL_SETTING ="/PriceGlobalSetting";
export const PRICE_DETERMINATION ="/PriceDetermination";
export const PRICE_MAINTENANCE ="/PriceMaintenance";
export const PRICE_COMPUTATION ="/PriceComputation";
export const VERIFY_EMAIL = "/verify-email/:id";

//Solution Builder Module
export const SOLUTION_BUILDER = "/solutionBuilder";
export const PORTFOLIO_AND_BUILDER = "/portfolioBuilder";
export const SOLUTION_TEMPLATES= "/SolutionTemplates";

export const PORTFOLIO_AND_BUILDER_NEW = "/portfolio/new";
export const SERVICE_NEW = "/service/new";
// export const TEST_REACT_TABLE_NESTED = "/react-table-nested";

export const SOLUTION_BUILDER_ANALYTICS = SOLUTION_BUILDER + "/analytics";
export const GUIDED_SOLUTION_BUILDER = SOLUTION_BUILDER + "/guide";
export const SOLUTION_BUILDER_SERVICE_PORTFOLIO = SOLUTION_BUILDER + "/servicePortfolio";
export const SOLUTION_BUILDER_NEW = SOLUTION_BUILDER + "/new";
export const SOLUTION_BUILDER_CUSTOMIZED_PORRTFOLIO = SOLUTION_BUILDER + "/customizedPortfolio";
export const SOLUTION_BUILDER_PORRTFOLIO_TEMP = SOLUTION_BUILDER + "/portfolioTemplates";
export const SOLUTION_TEMPLATE_SELECTED_PORTFOLIO_RESULT = SOLUTION_BUILDER + "/solutionTemplate";
export const SOLUTION_BUILDER_CUSTOM_PORTFOLIO_CREATE = SOLUTION_BUILDER + "/create";
export const CREATED_CUSTOM_PORTFOLIO_DETAILS = SOLUTION_BUILDER + "/customPortfolioTemplate";

// equipment-master
export const EQUIPMENT_MASTER = "/eqipment-master";
export const PARTS_360 = "/parts-360";
export const SERVICE_MASTER = "/service-master";
export const CONSUMABLE_MASTER = "/consumable-master";
export const CUSTOMER_MASTER = "/customer-master";

//Aux Admin
export const AUX_ADMIN_PROVISION = "/aux-admin/provision";
export const AUX_ADMIN_ACCOUNT = "/aux-admin/account";
export const ACCOUNT_RENEWAL_BILLING = "/account-billing";
export const ACCOUNT_CONFIGURATION = "/account-config";
export const ACCOUNT_SETTINGs = "/account-settings";
export const ACCOUNT_SUPPORT = "/account-support"
export const ACCOUNT_PACKAGES = "/packages"
export const PLAN_MOMENTUM="MOMENTUM";
export const PLAN_STARTER="STARTER";
export const PLAN_GROWTH="GROWTH";
export const PLAN_ENTERPRISE="ENTERPRISE";
